<template>
  <div class="page-card">
    <div class="row align-items-center mb-3">
      <div class="col-md-6 d-flex align-items-center gap-4">
        <h2 class="page-title">vitals</h2>
        <!-- <a href="javascript:void(0)" v-on:click="openAddEditModel()" class="add-link ms-4">Add new T & C <i
            class="bi bi-plus-circle-fill"></i></a> -->
        <!-- <a href="javascript:void(0)" v-on:click="onCreateUser()" class="add-link ms-4" 
            >add user <i class="bi bi-plus-circle-fill"></i
          ></a> -->
      </div>
      <div class="col-md-6">
        <div
          class="d-flex justify-content-end font-12 text-uppercase gap-2 align-items-center"
        >
          <span>show</span>
          <div class="bg-light-brand px-1">100</div>
          <span>entries</span>
        </div>
      </div>
    </div>

    <div class="table-wrap">
      <table class="table table-style">
        <thead>
          <tr>
            <th scope="col">
              <img
                src="images/sort-icon.svg"
                class="sort-cion"
                @click="sorting($event, 'id')"
              />
              ID
            </th>
            <th scope="col">
              <img
                src="images/sort-icon.svg"
                class="sort-cion"
                @click="sorting($event, 'title')"
              />
              Name
            </th>
            <th scope="col" class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loader">
            <td colspan="3" class="text-center">
              <div class="spinner-border spinner-color" role="status"></div>
            </td>
          </tr>
          <tr v-else v-for="item in appList" :key="item.id">
            <td>{{ item?.id }}</td>
            <td>
              <a style="cursor: pointer">{{ item?.title }}</a>
            </td>
            <td>
              <div class="action-ele">
                <!-- <div class="form-check form-switch font-18">
                                  <input class="form-check-input" v-on:click="updateLitStatus($event, item.id)" :value="item.status" :checked="item.status == 'ACTIVE' ? true : false" type="checkbox" role="switch" id="flexSwitchCheckChecked">
                              </div> -->
                <a
                  href="javascript:void(0)"
                  title="Edit"
                  v-on:click="openAddEditModel(item.id)"
                  ><img src="/images/icon-edit.svg" width="22" alt=""
                /></a>
                <a href="javascript:void(0)" title="Delete"
                  ><img src="/images/icon-archive.svg" width="18" alt=""
                /></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ErrorComponent @retry="getList(1)" ref="errorComponent" />
    </div>
    <div class="row">
      <div class="col-lg-12 mt-5 text-center">
        <div class="page-bottom-pagination text-right">
          <Pagination
            @page-change="pageChange"
            @items-per-page-change="itemsPerPageChange"
            ref="rolePagination"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- add Modal -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Vital Info</h1>
          <button
            type="button"
            class="btn-close"
            v-on:click="closeAddEditModel()"
          ></button>
        </div>
        <div class="modal-body form-style">
          <Form @submit="onSave()" ref="addEditApp">
            <div class="row">
              <div class="col-lg-12">
                <div class="page-card">
                  <div class="row align-items-center mb-3 border-bottom">
                    <div class="col-md-2 d-flex align-items-center gap-4">
                      <div class="dropdown card-dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          English
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <button class="dropdown-item" type="button">English</button>
                          </li>
                          <li>
                            <button class="dropdown-item" type="button">French</button>
                          </li>
                          <li>
                            <button class="dropdown-item" type="button">Japnise</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-10">
                      <ul
                        class="nav nav-tabs mb-3 tab-style-1 gap-2"
                        id="myTab"
                        role="tablist"
                      >
                        <li
                          class="nav-item"
                          role="presentation"
                          v-for="item in levelList"
                          :key="item.id"
                        >
                          <button
                            class="nav-link"
                            id="vital-infos"
                            v-on:click="changeTab(item.level)"
                            data-bs-toggle="tab"
                            data-bs-target="vital"
                            type="button"
                            role="tab"
                            aria-controls="vital"
                            aria-selected="true"
                          >
                            {{ $helperService.getTitleCase(item.level) }}
                          </button>
                        </li>
                      </ul>
                    </div>
                    <!-- <div class="col-md-1 text-end">
                      <div class="dropdown card-dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <i class="bi bi-circle-fill text-warning font-12 me-1"></i> Draft
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <div class="font-medium font-14 text-dark mb-2">
                            <i class="bi bi-info-circle"></i> Draft
                          </div>
                          <div class="text-secondary font-14 mb-4">
                            All article saved here before you publish them. You can edit anything whatever you want.
                          </div>
                          <div class="d-flex justify-content-between align-items-center">
                            <samp class="text-brand font-12">LEARN MORE</samp>
                            <button class="btn btn-dark-brand btn-sm">GOT IT</button>
                          </div>
                        </ul>
                      </div>
                    </div> -->
                  </div>
                  <div class="px-4">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="vital"
                        role="tabpanel"
                        aria-labelledby="vital-infos"
                        tabindex="0"
                      >
                        <div v-if="vitals != null">
                          <h3 class="font-18 fw-bold mb-1">
                            {{ vitals?.vital_master?.title }}
                          </h3>
                          <p class="mb-3 font-14 text-secondary">
                            Last edited
                            {{ $helperService.dateTimeValue(vitals?.updated_at) }} ago by
                            <a href="" class="text-primary">{{
                              vitals?.admin_user?.full_name
                            }}</a>
                          </p>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-floating mb-2">
                                <Field
                                  type="text"
                                  class="form-control"
                                  :disabled="true"
                                  name="vital_group"
                                  v-model="vitals.vital_group"
                                  rules="required:vital group"
                                  :validateOnInput="true"
                                  id="vital_group"
                                  placeholder="vital_group"
                                />
                                <label for="vital_group"
                                  >Vital Group<span class="text-danger">*</span>
                                </label>
                                <ErrorMessage name="vital_group" class="validation-msg" />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-3">
                              <div class="form-floating mb-2">
                                <Field
                                  type="text"
                                  class="form-control"
                                  name="min"
                                  v-model="vitals.min"
                                  id="min"
                                  placeholder="min"
                                />
                                <label for="min"
                                  >Min <span class="text-danger">*</span>
                                </label>
                                <!-- <ErrorMessage name="min" class="validation-msg" /> -->
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <div class="form-floating mb-2">
                                <Field
                                  type="text"
                                  class="form-control"
                                  name="max"
                                  v-model="vitals.max"
                                  rules="required:max|numeric"
                                  :validateOnInput="true"
                                  id="max"
                                  placeholder="max"
                                />
                                <label for="max"
                                  >Max <span class="text-danger">*</span>
                                </label>
                                <ErrorMessage name="max" class="validation-msg" />
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <div class="form-floating mb-2">
                                <Field
                                  type="text"
                                  class="form-control"
                                  name="level"
                                  v-model="vitals.level"
                                  rules="required:level"
                                  :validateOnInput="true"
                                  id="level"
                                  placeholder="level"
                                />
                                <label for="level"
                                  >Level <span class="text-danger">*</span>
                                </label>
                                <ErrorMessage name="level" class="validation-msg" />
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <div class="form-floating mb-2">
                                <Field
                                  type="text"
                                  class="form-control"
                                  name="ordering"
                                  v-model="vitals.ordering"
                                  id="ordering"
                                  placeholder="ordering"
                                />
                                <label for="ordering">Ordering </label>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <lable>Gender</lable>
                              <Field
                                v-slot="{ field }"
                                name="gender"
                                rules="required:gender,true"
                                :validateOnInput="true"
                                v-model="vitals.gender"
                              >
                                <Multiselect
                                  v-bind="field"
                                  ref="gender_multiselect"
                                  mode="single"
                                  trackBy="value"
                                  label="name"
                                  valueProp="value"
                                  placeholder="Select Type"
                                  class="form-control"
                                  v-model="vitals.gender"
                                  :options="genders"
                                  :searchable="true"
                                  :createOption="true"
                                />
                              </Field>
                            </div>
                            <div class="col-lg-3">
                              <lable>Color</lable>
                              <Field
                                name="color_code"
                                style="height: 40px !important; width: 100px !important"
                                type="color"
                                class="form-control form-control-color"
                                rules="required:color code,true"
                                :validateOnInput="true"
                                v-model="vitals.color_code"
                              />
                              <ErrorMessage name="color_code" class="validation-msg" />
                            </div>
                            <div class="col-lg-3">
                              <lable>Emoji</lable>
                              <Field
                                name="emoji"
                                v-slot="{ feild }"
                                style="height: 40px !important; width: 100px !important"
                                type="color"
                                class="form-control form-control-color"
                                :validateOnInput="true"
                                v-model="emoji"
                              >
                                <select
                                  class="form-control"
                                  name="emoji"
                                  v-on:change="updateStatusValue"
                                  v-bind="feild"
                                  v-model="emoji"
                                  id="emoji"
                                >
                                  <option
                                    v-for="item in emojis"
                                    :key="item.value"
                                    v-bind:value="item.value"
                                  >
                                    {{ item.value }}
                                  </option>
                                </select>
                              </Field>
                            </div>
                            <div class="col-lg-12 pt-3">
                              <div class="form-floating mb-2">
                                <Field
                                  type="text"
                                  class="form-control"
                                  name="status"
                                  v-model="vitals.status"
                                  rules="required:status"
                                  :validateOnInput="true"
                                  id="status"
                                  placeholder="status"
                                />
                                <label for="status"
                                  >Status <span class="text-danger">*</span></label
                                >
                                <ErrorMessage name="status" class="validation-msg" />
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-floating mb-2">
                                <Field
                                  type="text"
                                  class="form-control"
                                  name="short_description"
                                  v-model="vitals.short_desc"
                                  rules="required:short description"
                                  :validateOnInput="true"
                                  id="short_description"
                                  placeholder="Short Description"
                                />
                                <label for="status"
                                  >Short Description
                                  <span class="text-danger">*</span></label
                                >
                                <ErrorMessage name="status" class="validation-msg" />
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <Field
                                v-slot="{ field }"
                                rules="required:description,true"
                                name="description"
                                v-model="vitals.description"
                                :validateOnInput="true"
                              >
                                <label for="description"
                                  >Description <span class="text-danger">*</span></label
                                >
                                <textarea
                                  class="form-control"
                                  v-bind="field"
                                  v-model="vitals.description"
                                  rows="10"
                                  cols="50"
                                ></textarea>
                              </Field>
                              <ErrorMessage name="description" class="validation-msg" />
                            </div>
                            <div class="col-lg-6 pt-2">
                              <h6 class="text-primary">
                                <a
                                  href="javascript:void(0)"
                                  @click="OpenSelectImage($event)"
                                  >Upload Icon</a
                                >
                              </h6>
                              <div class="picture mb-3">
                                <img
                                  :src="
                                    vitals.icon
                                      ? vitals.icon
                                      : '/images/profile-placeholder.jpg'
                                  "
                                  class="image"
                                  id="img_hoder_1"
                                />
                                <input
                                  type="file"
                                  id="AddPhoto"
                                  title="Click to select file"
                                  accept="image/*"
                                  @change="onChange($event)"
                                />
                              </div>
                            </div>
                          </div>
                          <!-- <button class="btn btn-dark-brand mb-4">Save draft</button> -->
                        </div>
                        <div v-else>
                          <div
                            v-if="formLoader"
                            class="spinner-border spinner-color text-center"
                            role="status"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-footer border-0 justify-content-start"
              v-if="vitals != null"
            >
              <button id="save-btn" type="submit" class="btn btn-dark-brand px-4">
                Save
              </button>
              <button
                type="button"
                v-on:click="closeAddEditModel()"
                class="btn btn-light-brand px-4"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* @import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";
@import "quill/dist/quill.bubble.css"; */

.quillWrapper .ql-snow.ql-toolbar {
  padding-top: 8px !important;
  padding-bottom: 4px !important;
  height: 50px !important;
}

.spinner-color {
  color: "#351D4E";
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: "#351D4E";
}
</style>
<script>
// import { VueEditor } from "vue3-editor";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import "@vuepic/vue-datepicker/dist/main.css";
import { Form, Field, ErrorMessage } from "vee-validate";
// import moment from 'moment';
import $ from "jquery";
export default {
  name: "ContentPages",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
    Pagination,
    Multiselect,
  },
  data() {
    return {
      genders: [
        {
          name: "Male",
          value: "MALE",
        },
        {
          name: "Female",
          value: "FEMALE",
        },
        {
          name: "Both",
          value: "BOTH",
        },
      ],
      emoji: "🙂",
      emojis: [{ value: "🙂" }, { value: "🙁" }, { value: "😐" }, { value: "😒" }],
      vitals: null,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      levelList: [],
      vital_info_id: null,
      filterObj: {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: "",
      },
      appList: [],
      active_tab: "LOW",
      loader: false,
      tabIds: "tab_" + this.active_tab,
      liIds: "tab_" + this.active_tab + "_id",
      file: "",
      formLoader: false,
      vitla_information_id: "",
      vital_sign_id: "",
    };
  },
  mounted() {
    // this.active_tab = localStorage.getItem("active_tab")
    //   ? localStorage.getItem("active_tab")
    //   : "LOW";
    this.getList(1);
  },
  methods: {
    changeTab(tab) {
      this.active_tab = tab;
      this.tabIds = "tab_" + tab;
      this.liIds = "tab_" + tab + "_id";

      var value = "#tab_" + tab + "_id";
      if (tab) {
        console.log(value);
        window.$(value).click();
        this.onDetailByLevel(this.vital_id, tab);
      }
    },
    OpenSelectImage(e) {
      e.stopImmediatePropagation();
      window.$("#AddPhoto").click();
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    openAddEditModel(id) {
      this.vital_id = id;
      this.getVitalLevelList(id);
    },
    editAppDetails(id) {
      this.$router.push("/edit-app/" + id);
    },
    closeAddEditModel() {
      this.onClearForm("addEditApp");
      window.$("#addModal").modal("hide");
      this.vital_id = null;
      this.vital_info_id = null;
      (this.liIds = ""), (this.tabIds = ""), localStorage.removeItem("active_tab");
    },
    updateStatus(e) {
      var status = e.target.checked;
      console.log(status);
      if (status == true) {
        this.users.status = "ACTIVE";
      }
      if (status == false) {
        this.users.status = "INACTIVE";
      }
      console.log(status, this.users.status);
    },
    onSave() {
      this.vitals.emoji = this.emoji;
      console.log("data from vital save", this.vitals);
      var method = "POST";
      var url = "static-page";
      if (this.vital_info_id != null) {
        method = "PUT";
        url = "vital/" + this.vital_info_id + "/info";
      }
      this.$api
        .webRequest({
          _method: method,
          _buttonId: "save-btn",
          _action: url,
          _body: this.vitals,
          _hide_loader: true,
        })
        .then((res) => {
          this.$toast.success(res.message, {
            position: "top-right",
          });
          this.getList(1);
          this.closeAddEditModel();
          localStorage.removeItem("active_tab");
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
    onDetailByLevel(id, type) {
      this.formLoader = true;
      this.$api
        .webRequest({
          _method: "GET",
          _action: "vital-info/" + id + "/level/" + type.toUpperCase(),
        })
        .then((res) => {
          if (res.info != null) {
            this.vitla_information_id = res.info.id;
            this.vital_sign_id = res.info.vital_sign_id;
            this.formLoader = false;
            this.vitals = res.info;
            this.emoji = this.vitals.emoji;
            this.vital_info_id = res.info.id;
            this.vitals.vital_group = res.info.vital_master.group;
          } else {
            this.formLoader = false;
            this.vitals = null;
            this.vital_info_id = null;
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.rolePagination.itemsPerPage;
      this.getList(1);
    },
    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }
      this.loader = true;
      this.list = [];
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "vital-masters",
          _body: this.filterObj,
        })
        .then((res) => {
          this.loader = false;
          this.appList = res.list;
          // this.access = res.access;
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(res.count);
              }
            } else {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(0);
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("Sorry, There is no record found.");
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle();
              }
            }
          }
          if (this.$refs.rolePagination) {
            this.$refs.rolePagination.currentPage = this.currentPage;
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: "top-right",
          });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";
      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },

    updateLitStatus(e, item) {
      var obj = {};
      if (item.status == "ACTIVE") {
        obj.status = "INACTIVE";
      }
      if (item.status == "INACTIVE") {
        obj.status = "ACTIVE";
      }
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "page/" + item.id + "/status",
          _body: obj,
        })
        .then((res) => {
          this.$toast.success(res.message, {
            position: "top-right",
          });
          this.getList(1);
          this.closeAddEditModel();
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
    getVitalLevelList(vitalId) {
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "vital/level/" + vitalId,
        })
        .then((res) => {
          this.loader = false;
          this.levelList = res.list;
          if (this.levelList.length > 0 && this.levelList[0].level) {
            this.active_tab = this.levelList[0].level;
          }
          if (res.list) {
            this.onClearForm("addEditApp");
            window.$("#addModal").modal("show");
            this.changeTab(this.active_tab);
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
    onChange(e) {
      let files = e.target.files;
      this.file = files[0];
      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          window.$("#img_hoder_1").attr("src", e.target.result);
        };
      }
      reader.readAsDataURL(e.target.files[0]);
      this.uploadFile(files[0]);
    },
    uploadFile(file) {
      console.log("profile_image file", this.file);
      this.$api
        .uploadImageAPI({
          _action: "upload-icons/" + this.vitla_information_id,
          _key: "icon",
          _file: file,
        })
        .then((res) => {
          this.$toast.success(res.message, {
            position: "top-right",
          });
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
    updateStatusValue() {
      const sentence = this.vitals.status;
      const regexpEmojiPresentation = /\p{Emoji_Presentation}/gu;
      console.log("emoji", sentence.match(regexpEmojiPresentation));
      this.vitals.status = sentence.replaceAll(regexpEmojiPresentation, this.emoji);
    },
  },
};
</script>
