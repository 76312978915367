<template>

    <!-- FAQ Page Intro Section on the Left -->


    <!-- Main FAQ Section on the Right -->



    <div class="">
        <div class="row space">
            <div class="col-md-5 intro">
                <div class="col-md-12 page-card">
                    <!-- FAQ Categories Header -->
                    <div class="row align-items-center mb-3">
                        <div class="col-md-6 d-flex align-items-center">
                            <h2 class="page-title">Trivia</h2>
                            <a href="javascript:void(0)" @click="openFaqModal('trivia')"
                                class="btn btn-link d-flex align-items-center ms-2">
                                Add NEW <i class="bi bi-plus-circle-fill fs-5 ms-2"></i>
                            </a>




                        </div>
                        <div class="col-md-6">
                            <div class="d-flex justify-content-end font-12 text-uppercase gap-2 align-items-center">
                                <span>show</span>
                                <div class="bg-light-brand px-2 py-1 rounded">{{ itemsPerPage }}</div>
                                <span>entries</span>
                            </div>
                        </div>
                    </div>

                    <!-- FAQ Categories Table -->
                    <div class="table-wrap">
                        <table class="table table-style">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <img src="/images/sort-icon.svg" class="sort-icon"
                                            @click="sorting($event, 'id')" />
                                        ID
                                    </th>
                                    <th scope="col">
                                        <img src="/images/sort-icon.svg" class="sort-icon"
                                            @click="sorting($event, 'name')" />
                                        Name
                                    </th>
                                    <!-- <th scope="col">Usage</th>
                    <th scope="col">Order</th> -->
                                    <th scope="col" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="loader">
                                    <td colspan="5" class="text-center">
                                        <div class="spinner-border spinner-color" role="status"></div>
                                    </td>
                                </tr>
                                <tr
  v-for="item in faqItems"
  :key="item.id"
  :class="{ 'selected-row': selectedRowId === item.id }"
  @click="selectRow(item.id)"
>
  <td>TR{{ item.id }}</td>
  <td
    @click.stop="openEditForm(item, 'trivia')"
    class="cursor-pointer"
  >
    {{ item.title }}
  </td>
  <td class="text-center">
    <!-- Actions -->
    <div class="action-ele">
      <a
        href="javascript:void(0)"
        @click="openEditForm(item, 'trivia')"
        title="Edit"
      >
        <img src="/images/icon-edit.svg" width="22" alt="Edit" />
      </a>
      <a
        href="javascript:void(0)"
        @click="viewFaq(item.id, item.archive)"
        :class="{ disabled: item.archive === 1 }"
        title="Archive"
      >
        <img
          src="/images/icon-archive.svg"
          width="22"
          :alt="item.archive === 1 ? 'Unarchive' : 'Archive'"
        />
      </a>
      <a
        href="javascript:void(0)"
        @click="deleteFaq(item.id)"
        title="Delete"
      >
        <img src="/images/icon-delete.svg" width="18" alt="Delete" />
      </a>
    </div>
  </td>
</tr>

                            </tbody>

                        </table>
                    </div>
                </div>
                <div class="col-md-12 page-card">
                    <!-- FAQ Categories Header -->
                    <div class="row align-items-center mb-3">
                        <div class="col-md-6 d-flex align-items-center">
                            <h2 class="page-title">ADS</h2>
                            <a href="javascript:void(0)" @click="openFaqModal('ads')"
                                class="btn btn-link d-flex align-items-center ms-2">
                                Add NEW <i class="bi bi-plus-circle-fill fs-5 ms-2"></i>
                            </a>

                        </div>
                        <div class="col-md-6">
                            <div class="d-flex justify-content-end font-12 text-uppercase gap-2 align-items-center">
                                <span>show</span>
                                <div class="bg-light-brand px-2 py-1 rounded">{{ itemsPerPage }}</div>
                                <span>entries</span>
                            </div>
                        </div>
                    </div>

                    <!-- FAQ Categories Table -->
                    <div class="table-wrap">
                        <table class="table table-style">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>

                                    <th>Order</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="loader">
                                    <td colspan="6" class="text-center">
                                        <div class="spinner-border spinner-color" role="status"></div>
                                    </td>
                                </tr>
                                <tr v-else v-for="item in adsItems" :key="item.id">
                                    <td>AD{{ item.id }}</td>
                                    <td @click="openEditForm(item, 'ads')" 
        class="cursor-pointer hover:bg-gray-100"
        style="cursor: pointer;">
        {{ item.title }}
    </td>
                                    <td>{{ item.order }}</td>
                                    <td>
                                        <div class="action-ele">
                                            <a href="javascript:void(0)" @click="openEditForm(item, 'ads')"
                                                title="Edit">
                                                <img src="/images/icon-edit.svg" width="22" alt="Edit" />
                                            </a>
                                            <a href="javascript:void(0)" 
   @click="viewAds(item.id, item.archive)" 
   :class="{ 'disabled': item.archive === 1 }"
   title="Archive">
    <img src="/images/icon-archive.svg" 
         width="22" 
         :alt="item.archive === 1 ? 'Unarchive' : 'Archive'" />
</a>
                                            <a href="javascript:void(0)" @click="deleteFaq(item.id)" title="Delete">
    <img src="/images/icon-delete.svg" width="18" alt="Delete" />
</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
            <div class="col-md-6 page-card">
                <div class="">
                    <div class="">
                        <div class="">
                            <h5 class="modal-title">{{ isEditing ? 'Edit' : 'Add New' }} {{ context === 'trivia' ?
                                'Trivia' : 'ADS' }}</h5>
                        </div>
                        <div class="modal-body">
                            <form @submit.prevent="isEditing ? updateFaq() : createFaq()">
                                <div class="mb-3">
                                    <label for="title" class="form-label">Title/Text</label>
                                    <input type="text" class="form-control" id="title" v-model="faqForm.title"
                                        placeholder="Enter Title" required />
                                </div>

                                <div class="mb-3" v-if="context === 'ads'">
                                    <label for="media_file" class="form-label">Media File</label>
                                    <input type="file" class="form-control" id="media_file"
                                        @change="handleFileUpload" />
                                    <small v-if="faqForm.media_file">Current File: {{ faqForm.media_file.name ||
                                        faqForm.media_file }}</small>
                                </div>

                                <div class="mb-3" v-if="context === 'ads'">
                                    <label for="media_type" class="form-label">Media Type</label>
                                    <select class="form-select" id="media_type" v-model="faqForm.media_type" required>
                                        <option value="video">video</option>
                                        <option value="image">image</option>
                                        <option value="gif">gif</option>
                                    </select>
                                </div>

                                <div class="mb-3">
                                    <label for="order" class="form-label">Order</label>
                                    <input type="number" class="form-control" id="order" v-model="faqForm.order"
                                        placeholder="Enter Display Order" required />
                                </div>

                                <div class="mb-3">
                                    <label for="duration" class="form-label">Duration (in seconds)</label>
                                    <input type="number" class="form-control" id="duration" v-model="faqForm.duration"
                                        placeholder="Enter Duration" :required="context === 'ads'" />
                                </div>

                                <div class="mb-3">
                                    <label for="start_time" class="form-label">Start Time</label>
                                    <input type="datetime-local" class="form-control" id="start_time"
                                        v-model="faqForm.start_time" required />
                                </div>

                                <div class="mb-3">
                                    <label for="end_time" class="form-label">End Time</label>
                                    <input type="datetime-local" class="form-control" id="end_time"
                                        v-model="faqForm.end_time" required />
                                </div>

                                <button type="submit" class="btn btn-primary w-100">
                                    {{ isEditing ? 'Update' : 'Save' }}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- <div class="col-md-5 intro"> -->

    <!-- </div> -->


    <!-- <div v-if="showFaqModal" class="modal-overlay">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add New {{ context === 'trivia' ? 'Trivia' : 'ADS' }}</h5>
                <button type="button" class="btn-close" @click="closeFaqModal"></button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="createFaq">
                    <div class="mb-3">
                        <label for="title" class="form-label">Title/Text</label>
                        <input type="text" class="form-control" id="title" v-model="faqForm.title"
                            placeholder="Enter Title" required />
                    </div>

                    <div class="mb-3" v-if="context === 'ads'">
                        <label for="media_file" class="form-label">Media File</label>
                        <input type="file" class="form-control" id="media_file" @change="handleFileUpload"
                            :required="context === 'ads'" />
                    </div>

                    <div class="mb-3" v-if="context === 'ads'">
                        <label for="media_type" class="form-label">Media Type</label>
                        <select class="form-select" id="media_type" v-model="faqForm.media_type" required>
                            <option value="video">video</option>
                            <option value="image">image</option>
                            <option value="image">gif</option>
                        </select>
                    </div>

                    <div class="mb-3" v-else>
                        <input type="hidden" id="media_type" v-model="faqForm.media_type" />
                    </div>

                    <div class="mb-3">
                        <label for="order" class="form-label">Order</label>
                        <input type="number" class="form-control" id="order" v-model="faqForm.order"
                            placeholder="Enter Display Order" required />
                    </div>

                    <div class="mb-3">
                        <label for="duration" class="form-label">Duration (in seconds)</label>
                        <input type="number" class="form-control" id="duration" v-model="faqForm.duration"
                            placeholder="Enter Duration" :required="context === 'ads'" />
                    </div>

                    <div class="mb-3">
                        <label for="start_time" class="form-label">Start Time</label>
                        <input type="datetime-local" class="form-control" id="start_time" v-model="faqForm.start_time"
                            required />
                    </div>

                    <div class="mb-3">
                        <label for="end_time" class="form-label">End Time</label>
                        <input type="datetime-local" class="form-control" id="end_time" v-model="faqForm.end_time"
                            required />
                    </div>

                    <button type="submit" class="btn btn-primary w-100">
                        Save
                    </button>
                </form>
            </div>
        </div>
    </div> -->


</template>

<script>

import WebService from "@/services/WebService";

export default {
  name: 'FaqPage',
  data() {
    return {
      showFaqModal: false,
      faqItems: [],
      adsItems: [],
      context: 'trivia',
      isEditing: false,
      currentEditingId: null,
      loader: false,
      itemsPerPage: 10,
      faqForm: {
        title: '',
        media_file: null,
        media_type: 'text'|'video'|'image'|'gif',
        order: '',
        duration: '',
        start_time: '',
        end_time: '',
      },
    };
  },

  created() {
    this.initializeData();
  },

  methods: {
    selectRow(id) {
      this.selectedRowId = id; // Set the selected row ID
    },
    async initializeData() {
      await Promise.all([
        this.getFaqItems(),
        this.getAdsItems()
      ]);
    },


    async getFaqItems() {
    try {
        this.loader = true;
        const response = await WebService.webRequest({
            _method: 'GET',
            _action: 'trivia-text',
            _body: {}
        });
        
        if (response?.data) {
            this.faqItems = response.data.map(item => ({
                ...item,
                archive: parseInt(item.archive) // Ensure archive is a number
            }));
        } else {
            this.faqItems = [];
        }
    } catch (error) {
        console.error('Error fetching FAQ items:', error);
        this.faqItems = [];
    } finally {
        this.loader = false;
    }
},

async getAdsItems() {
    try {
        this.loader = true;
        const response = await WebService.webRequest({
            _method: 'GET',
            _action: 'trivia',
            _body: {}
        });
        
        if (response?.data) {
            this.adsItems = response.data.map(item => ({
                ...item,
                archive: parseInt(item.archive) // Ensure archive is a number
            }));
        } else {
            this.adsItems = [];
        }
    } catch (error) {
        console.error('Error fetching ADS items:', error);
        this.adsItems = [];
    } finally {
        this.loader = false;
    }
},
formatDateTime(dateStr) {
        if (!dateStr) return '';
        const date = new Date(dateStr);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:00`;
    },

    async createFaq() {
        try {
            this.loader = true;
            
            // Basic validation
            if (!this.faqForm.title?.trim()) {
                throw new Error('Title is required');
            }
            
            // Create FormData object
            const formData = new FormData();
            
            // Append basic fields
            formData.append('title', this.faqForm.title.trim());
            formData.append('order', this.faqForm.order);
            formData.append('duration', this.faqForm.duration);
            
            // Format and append dates
            const startTime = this.formatDateTime(this.faqForm.start_time);
            const endTime = this.formatDateTime(this.faqForm.end_time);
            formData.append('start_time', startTime);
            formData.append('end_time', endTime);

            // Handle context-specific data
            if (this.context === 'ads') {
                if (!this.faqForm.media_type) {
                    throw new Error('Media type is required for ads');
                }
                formData.append('media_type', this.faqForm.media_type);
                if (this.faqForm.media_file) {
                    formData.append('media_file', this.faqForm.media_file);
                }
            } else {
                formData.append('media_type', 'text');
            }

            // Debug log
            console.log('Submitting form data:');
            for (let pair of formData.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }

            const response = await WebService.webRequest({
                _method: 'POST',
                _action: 'trivia',  // Use the same endpoint for both
                _body: formData
            });

            if (response.success) {
                await this.initializeData();
                this.resetFaqForm();
                // alert(`${this.context === 'trivia' ? 'Trivia' : 'ADS'} created successfully!`);
            } else {
                throw new Error(response.message || 'Failed to create record');
            }
        } catch (error) {
            console.error('Error in createFaq:', error);
            alert(error.message || 'An error occurred while creating the record');
        } finally {
            this.loader = false;
        }
    },

    handleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
            // Validate file type
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'];
            if (!allowedTypes.includes(file.type)) {
                alert('Invalid file type. Please upload jpg, png, gif, or mp4 files only.');
                event.target.value = ''; // Clear the file input
                return;
            }
            
            // Validate file size (20MB = 20 * 1024 * 1024 bytes)
            if (file.size > 20 * 1024 * 1024) {
                alert('File size must not exceed 20MB');
                event.target.value = '';
                return;
            }

            this.faqForm.media_file = file;
            this.faqForm.media_type = this.getMediaType(file.type);
            console.log('File selected:', file.name, 'Type:', this.faqForm.media_type);
        }
    },

    getMediaType(mimeType) {
        const validTypes = {
            'image/jpeg': 'image',
            'image/png': 'image',
            'image/gif': 'gif',
            'video/mp4': 'video'
        };
        return validTypes[mimeType] || 'text';
    },

  // Update WebService.js to handle FormData
  webRequest({ _method, _action, _body, _headers = {} }) {
    const url = `/api/${_action}`;
    
    const config = {
      method: _method,
      headers: {
        'Accept': 'application/json',
        ..._headers
      }
    };

    // Only add Content-Type for non-FormData requests
    if (!(_body instanceof FormData)) {
      config.headers['Content-Type'] = 'application/json';
      config.body = JSON.stringify(_body);
    } else {
      config.body = _body;
    }

    return fetch(url, config).then(response => response.json());
  },


  resetFaqForm() {
        this.faqForm = {
            title: '',
            media_file: null,
            media_type: this.context === 'trivia' ? 'text' : '',
            order: '',
            duration: '',
            start_time: '',
            end_time: '',
        };
        this.isEditing = false;
        this.currentEditingId = null;
    },

    openFaqModal(context) {
      this.context = context;
      this.isEditing = false;
      this.currentEditingId = null;
      this.resetFaqForm();
      // Note: No need to set showFaqModal since we're using the inline form
    },
    async updateFaq() {
    try {
        this.loader = true;
        
        // Create FormData object
        const formData = new FormData();
        
        // Append basic fields - only append if they have values
        if (this.faqForm.title?.trim()) {
            formData.append('title', this.faqForm.title.trim());
        }
        if (this.faqForm.order) {
            formData.append('order', this.faqForm.order);
        }
        if (this.faqForm.duration) {
            formData.append('duration', this.faqForm.duration);
        }
        
        // Format and append dates if they exist
        if (this.faqForm.start_time) {
            const startTime = this.formatDateTime(this.faqForm.start_time);
            formData.append('start_time', startTime);
        }
        if (this.faqForm.end_time) {
            const endTime = this.formatDateTime(this.faqForm.end_time);
            formData.append('end_time', endTime);
        }

        // Handle media type
        if (this.context === 'ads' && this.faqForm.media_type) {
            formData.append('media_type', this.faqForm.media_type);
            if (this.faqForm.media_file instanceof File) {
                formData.append('media_file', this.faqForm.media_file);
            }
        } else {
            formData.append('media_type', 'text');
        }

        // Debug log
        console.log('Updating form data for ID:', this.currentEditingId);
        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        const response = await WebService.webRequest({
            _method: 'PUT',
            _action: `trivia/${this.currentEditingId}`, // Add ID to URL
            _body: formData,
            _headers: {
                // Don't set Content-Type, let the browser set it with the boundary
            }
        });

        if (response.success) {
            await this.initializeData();
            this.resetFaqForm();
            this.isEditing = false;
            this.currentEditingId = null;
            // alert(`${this.context === 'trivia' ? 'Trivia' : 'ADS'} updated successfully!`);
        } else {
            throw new Error(response.message || 'Failed to update record');
        }
    } catch (error) {
        console.error('Error in updateFaq:', error);
        alert(error.message || 'An error occurred while updating the record');
    } finally {
        this.loader = false;
    }
},





    // Add view methods
    async viewFaq(id, currentArchiveStatus) {
    try {
        // Create FormData object for the archive update
        const formData = new FormData();
        formData.append('archive', currentArchiveStatus === 1 ? '0' : '1'); // Toggle archive status

        const response = await WebService.webRequest({
            _method: 'PUT',
            _action: `trivia/${id}`,
            _body: formData
        });

        if (response.success) {
            await this.initializeData();
        } else {
            throw new Error(response.message || 'Failed to update archive status');
        }
    } catch (error) {
        console.error('Error updating archive status:', error);
        alert('Error updating archive status');
    }
},

async viewAds(id, currentArchiveStatus) {
    try {
        const formData = new FormData();
        formData.append('archive', currentArchiveStatus === 1 ? '0' : '1'); // Toggle archive status

        const response = await WebService.webRequest({
            _method: 'PUT',
            _action: `trivia/${id}`,
            _body: formData
        });

        if (response.success) {
            await this.initializeData();
        } else {
            throw new Error(response.message || 'Failed to update archive status');
        }
    } catch (error) {
        console.error('Error updating archive status:', error);
        alert('Error updating archive status');
    }
},
    // Add delete methods
    async deleteFaq(id) {
      if (confirm('Are you sure you want to delete this item?')) {
        try {
          const response = await WebService.webRequest({
            _method: 'DELETE',
            _action: `trivia/${id}`,
            _body: {}
          });
          if (response && response.success) {
            await this.getFaqItems();
            window.location.reload();

            // alert('Item deleted successfully');
          }
        } catch (error) {
          console.error('Error deleting FAQ:', error);
          alert('Error deleting item');
        }
      }
    },
//     async getAdsItems() {
//   try {
//     this.loader = true;
//     const response = await WebService.webRequest({
//       _method: 'GET',
//       _action: 'trivia', // Endpoint for media trivia
//       _body: {}
//     });
    
//     if (response?.data) {
//       this.adsItems = response.data;
//     } else {
//       this.adsItems = [];
//     }
//   } catch (error) {
//     console.error('Error fetching ADS items:', error);
//     this.adsItems = [];
//   } finally {
//     this.loader = false;
//   }
// },
    
openEditForm(item, context) {
    this.context = context;
    this.isEditing = true;
    this.currentEditingId = item.id;

    // Highlight the selected row
    this.selectedRowId = item.id;

    // Populate the form data
    const startDate = new Date(item.start_time);
    const endDate = new Date(item.end_time);

    this.faqForm = {
      title: item.title,
      media_file: null, // Will be updated if the user selects a new file
      media_type: item.media_type,
      order: item.order,
      duration: item.duration,
      start_time: startDate.toISOString().slice(0, 16), // Format: YYYY-MM-DDThh:mm
      end_time: endDate.toISOString().slice(0, 16),
    };
  },
  async deleteAds(id) {
    if (confirm('Are you sure you want to delete this item?')) {
        try {
            const response = await WebService.webRequest({
                _method: 'DELETE',
                _action: `trivia/${id}`, // Assuming the same endpoint works for ADS
                _body: {} // Adjust the request body if necessary
            });

            if (response && response.success) {
                // Refresh the adsItems list
                await this.getAdsItems();
                alert('Ad deleted successfully');
                window.location.reload();

            } else {
                throw new Error(response.message || 'Failed to delete the ad');
            }
        } catch (error) {
            console.error('Error deleting ad:', error);
            alert(error.message || 'An error occurred while deleting the ad');
        }
    }
},
  }
};
</script>


<style scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #4caf50;
}

input:checked+.slider:before {
    transform: translateX(14px);
}

.faq-intro {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.faq-intro-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 15px;
}
.selected-row {
  background-color: #43c8da; /* Light blue background */
  transition: background-color 0.3s ease; /* Smooth transition */
}


.faq-intro-title1 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 15px;
}

.faq-intro-description {
    font-size: 1rem;
    color: #555;
}

.page-title {
    font-size: 1.5rem;
    font-weight: 600;
}

.table-style th {
    position: relative;
}

.sort-icon {
    cursor: pointer;
    margin-right: 0.5rem;
}

.action-ele {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.spinner-color {
    color: #351d4e;
}

.Intro {
    margin-top: 20px;

}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    max-width: 90%;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}

.modal-body {
    padding: 15px 0;
}
.disabled {
    opacity: 0.4;
    /* pointer-events: none; */
    /* cursor: not-allowed; */
    filter: grayscale(100%);
}

.action-ele a.disabled img {
    opacity: 0.4;
    filter: grayscale(100%);
}
.cursor-pointer {
    cursor: pointer;
}

.cursor-pointer:hover {
    background-color: #f8f9fa;
    transition: background-color 0.2s ease;
}
</style>