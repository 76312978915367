<template>
  <div class="page-card">
    <div class="row">
      <!-- FAQ Page Intro Section on the Left -->


      <!-- Main FAQ Section on the Right -->
      <div class="col-md-12">
        <!-- FAQ Categories Header -->
        <div class="row align-items-center mb-3">
          <div class="col-md-6 d-flex align-items-center">
            <h2 class="page-title">FAQ Categories</h2>
            <a href="javascript:void(0)" @click="openAddCategoryModal"
              class="btn btn-link d-flex align-items-center ms-4">
              Add Category <i class="bi bi-plus-circle-fill fs-5 ms-2"></i>
            </a>
          </div>
          <div class="col-md-6">
            <div class="d-flex justify-content-end font-12 text-uppercase gap-2 align-items-center">
              <span>show</span>
              <div class="bg-light-brand px-2 py-1 rounded">{{ itemsPerPage }}</div>
              <span>entries</span>
            </div>
          </div>
        </div>

        <!-- FAQ Categories Table -->
        <div class="table-wrap">
          <table class="table table-style">
            <thead>
              <tr>
                <th scope="col">
                  <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'id')" />
                  ID
                </th>
                <th scope="col">
                  <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'name')" />
                  Name
                </th>
                <th scope="col">Usage</th>
                <th scope="col">Order</th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loader">
                <td colspan="5" class="text-center">
                  <div class="spinner-border spinner-color" role="status"></div>
                </td>
              </tr>
              <tr v-else v-for="(category) in faqCategories" :key="category.id">
                <td>{{ category.id }}</td>
                <td>{{ category.name }}</td>
                <td>
                  <span class="px-4 py-1 rounded" :class="{
                    'bg-success text-white': category.usage === 'in use',
                    'bg-light': category.usage === 'not used'
                  }">
                    {{ category.usage }}
                  </span>
                </td>
                <td>{{ category.order }}</td>
                <td class="text-center">
                  <div class="action-ele">
                    <a href="javascript:void(0)" @click="viewCategory(category.id)" title="View">
                      <img src="/images/icon-view.svg" width="22" alt="View" />
                    </a>
                    <a href="javascript:void(0)" @click="editCategory(category.id)" title="Edit">
                      <img src="/images/icon-edit.svg" width="22" alt="Edit" />
                    </a>
                    <a href="javascript:void(0)" @click="deleteCategory(category.id)" title="Delete">
                      <img src="/images/icon-archive.svg" width="18" alt="Delete" />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div class="row">
      <div class="col-md-5 intro">
        <div class="faq-intro">
          <h3 class="faq-intro-title">FQA page intro </h3>
          <div class="d-flex justify-content-between align-items-center">

            <strong class="faq-intro-title1">{{ faqIntro.title }}</strong>

            <a href="javascript:void(0)" @click="editCategory(faqIntro?.id)" title="Edit">
              <img src="/images/icon-edit.svg" width="22" alt="Edit" />
            </a>
          </div>
          <p class="faq-intro-description">{{ formatDate(faqIntro.created_at) }}</p>
          <div class="faq-intro-content">
            <p class="faq-intro-description">{{ faqIntro.description }}</p>

          </div>
        </div>
        <div class="col-md-12 page-card">
          <!-- FAQ Categories Header -->
          <div class="row align-items-center mb-3">
            <div class="col-md-6 d-flex align-items-center">
              <h2 class="page-title">SET FAQ ITEM</h2>
              <a href="javascript:void(0)" @click="openFaqModal" class="btn btn-link d-flex align-items-center ms-2">
                Add NEW <i class="bi bi-plus-circle-fill fs-5 ms-2"></i>
              </a>

            </div>
            <div class="col-md-6">
              <div class="d-flex justify-content-end font-12 text-uppercase gap-2 align-items-center">
                <span>show</span>
                <div class="bg-light-brand px-2 py-1 rounded">{{ itemsPerPage }}</div>
                <span>entries</span>
              </div>
            </div>
          </div>

          <!-- FAQ Categories Table -->
          <div class="table-wrap">
            <table class="table table-style">
              <thead>
                <tr>
                  <th scope="col">
                    <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'id')" />
                    ID
                  </th>
                  <th scope="col">
                    <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'name')" />
                    Name
                  </th>
                  <!-- <th scope="col">Usage</th>
                  <th scope="col">Order</th> -->
                  <th scope="col" class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loader">
                  <td colspan="5" class="text-center">
                    <div class="spinner-border spinner-color" role="status"></div>
                  </td>
                </tr>
                <tr v-else v-for="(item) in faqItems" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.question }}</td>
                  <!-- <td>{{ item.answer }}</td> -->
                  <!-- <td>{{ item.order }}</td> -->
                  <td class="text-center">
                    <div class="action-ele">
                      <label class="switch">
                        <input type="checkbox" :checked="item.status === 1" @change="toggleFaqStatus(item)" />
                        <span class="slider round"></span>
                      </label>
                      <a href="javascript:void(0)" @click="viewCategory(category.id)" title="View">
                      <img src="/images/icon-view.svg" width="22" alt="View" />
                    </a>
                      <a href="javascript:void(0)" @click="editFaq(item.id)" title="Edit">
                        <img src="/images/icon-edit.svg" width="22" alt="Edit" />
                      </a>
                      <a href="javascript:void(0)" @click="deleteFaq(item.id)" title="Delete">
                        <img src="/images/icon-delete.svg" width="18" alt="Delete" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6 page-card">
        <div class="">
          <div class="">
            <div class="">
              <h5 class="modal-title">Add New FAQ</h5>
              <!-- <button type="button" class="btn-close" @click="closeFaqModal"></button> -->
            </div>
            <div class="modal-body">
              <form @submit.prevent="createFaq">
                <div class="mb-3">
                  <label for="question" class="form-label">Question</label>
                  <input type="text" class="form-control" id="question" v-model="faqForm.question" required />
                </div>
                <div class="mb-3">
                  <label for="answer" class="form-label">Answer</label>
                  <textarea class="form-control" id="answer" v-model="faqForm.answer" rows="3" required></textarea>
                </div>
                <div class="mb-3">
                  <label for="order" class="form-label">Order</label>
                  <input type="number" class="form-control" id="order" v-model="faqForm.order" required />
                </div>
                <div class="mb-3">
                  <label for="category" class="form-label">Category</label>
                  <select class="form-select" id="category" v-model="faqForm.faq_category_id" required>
                    <option v-for="category in faqCategories" :key="category.id" :value="category.id">
                      {{ category.name }}
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="status" class="form-label">Status</label>
                  <select class="form-select" id="status" v-model="faqForm.status" required>
                    <option :value="true">Active</option>
                    <option :value="false">Inactive</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="is_draft" class="form-label">Is Draft</label>
                  <select class="form-select" id="is_draft" v-model="faqForm.is_draft" required>
                    <option :value="false">No</option>
                    <option :value="true">Yes</option>
                  </select>
                </div>
                <button type="submit" class="btn btn-primary">Save</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-md-5 intro"> -->

  <!-- </div> -->


  <!-- <div v-if="showFaqModal" class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add New FAQ</h5>
        <button type="button" class="btn-close" @click="closeFaqModal"></button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="createFaq">
          <div class="mb-3">
            <label for="question" class="form-label">Question</label>
            <input type="text" class="form-control" id="question" v-model="faqForm.question" required />
          </div>
          <div class="mb-3">
            <label for="answer" class="form-label">Answer</label>
            <textarea class="form-control" id="answer" v-model="faqForm.answer" rows="3" required></textarea>
          </div>
          <div class="mb-3">
            <label for="order" class="form-label">Order</label>
            <input type="number" class="form-control" id="order" v-model="faqForm.order" required />
          </div>
          <div class="mb-3">
            <label for="category" class="form-label">Category</label>
            <select class="form-select" id="category" v-model="faqForm.faq_category_id" required>
              <option v-for="category in faqCategories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label for="status" class="form-label">Status</label>
            <select class="form-select" id="status" v-model="faqForm.status" required>
              <option :value="true">Active</option>
              <option :value="false">Inactive</option>
            </select>
          </div>
          <div class="mb-3">
            <label for="is_draft" class="form-label">Is Draft</label>
            <select class="form-select" id="is_draft" v-model="faqForm.is_draft" required>
              <option :value="false">No</option>
              <option :value="true">Yes</option>
            </select>
          </div>
          <button type="submit" class="btn btn-primary">Save</button>
        </form>
      </div>
    </div>
  </div> -->

</template>

<script>

import WebService from "@/services/WebService"; // Adjust the path if needed

export default {
  name: 'FaqPage',
  data() {
    return {
      showFaqModal: false,
      faqCategories: [],
      faqItems: [],
      faqIntro: {
        title: 'Frequently Asked Questions',
        description: 'Remote photoplethysmography (rPPG) is changing how we measure vital signs like heart rate, offering a non-contact, innovative solution. Below are some commonly asked questions to help you understand this groundbreaking technology.'
      },
      currentPage: 1,
      itemsPerPage: 10,
      loader: false,
      faqForm: {
        question: '',
        answer: '',
        order: '',
        faq_category_id: '',
        status: true,
        is_draft: false,
      },
    };
  },
  mounted() {
    this.getCategories();
    this.getFaqItems();
    this.getFaqIntro(); // Fetch intro data

  },
  methods: {
    async toggleFaqStatus(item) {
      try {
        const previousStatus = item.status;
        item.status = item.status === 1 ? 0 : 1;

        const response = await WebService.webRequest({
          _method: 'PUT',
          _action: 'faqs-status',
          _body: {
            id: item.id,
            status: item.status,
          },
        });

        if (response.success) {
          console.log("FAQ status updated successfully:", response.data);
        } else {
          item.status = previousStatus; // Revert status
          console.error("Failed to update FAQ status:", response.message);
        }
      } catch (error) {
        item.status = item.status === 1 ? 0 : 1; // Revert status
        console.error("Error:", error.message);
      }
    },


    async createFaq() {
      try {
        // Display a loading spinner (optional)
        this.loader = true;

        // Send API request
        const response = await WebService.webRequest({
          _method: 'POST',
          _action: 'storeFAQ',
          _body: {
            question: this.faqForm.question,
            answer: this.faqForm.answer,
            faq_category_id: this.faqForm.faq_category_id,
            order: this.faqForm.order,
            status: this.faqForm.status,
            is_draft: this.faqForm.is_draft,
            last_edited_by: 'Admin', // Assuming 'Admin' for simplicity
          },
        });

        // Check if the API was successful
        if (response.success) {
          console.log('FAQ created successfully:', response);

          // Refresh FAQ list
          await this.getFaqItems();

          // Reset form data
          this.resetFaqForm();

          // Close modal
          this.closeFaqModal();
        } else {
          console.error('Error creating FAQ:', response.message);
          alert('Failed to create FAQ: ' + response.message);
        }
      } catch (error) {
        // Handle errors
        console.error('Error creating FAQ:', error.message);
        alert('An error occurred while creating FAQ.');
      } finally {
        // Hide loading spinner (if applicable)
        this.loader = false;
      }
    },

    resetFaqForm() {
      this.faqForm = {
        question: '',
        answer: '',
        order: '',
        faq_category_id: '',
        status: true, // Resetting to default
        is_draft: false, // Resetting to default
      };
    },

    closeFaqModal() {
      this.showFaqModal = false;
    },

    openFaqModal() {
      this.showFaqModal = true;
    },

    formatDate(dateString) {
      if (!dateString) return ''; // Handle null or undefined dates
      const date = new Date(dateString);
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

      // Add the ordinal suffix for the day (e.g., 23rd, 1st, 2nd)
      const day = date.getDate();
      const ordinal =
        day % 10 === 1 && day !== 11
          ? 'st'
          : day % 10 === 2 && day !== 12
            ? 'nd'
            : day % 10 === 3 && day !== 13
              ? 'rd'
              : 'th';

      return formattedDate.replace(String(day), `${day}${ordinal}`);
    },
    async getFaqIntro() {
      try {
        console.log("Fetching FAQ intro...");
        const response = await WebService.webRequest({
          _method: "GET",
          _action: "faq-intro", // Adjust the endpoint if necessary
          _body: {},
        });
        console.log("FAQ Intro Response:", response);
        this.faqIntro = response.data || {}; // Bind the response to `faqIntro`
      } catch (error) {
        console.error("Error fetching FAQ intro:", error.message);
      }
    },
    async getCategories() {
      try {
        console.log("Fetching categories...");
        this.loader = true;
        const response = await WebService.webRequest({
          _method: "GET",
          _action: "categories", // Adjust endpoint if necessary
          _body: {},
        });
        console.log("Categories Response:", response);
        this.faqCategories = response.data || [];
        this.loader = false;
      } catch (error) {
        console.error("Error fetching categories:", error.message);
        this.loader = false;
      }
    },
    async getFaqItems() {
      try {
        console.log("Fetching FAQ items...");
        this.loader = true;
        const response = await WebService.webRequest({
          _method: "GET",
          _action: "get-faq", // Adjust endpoint if necessary
          _body: {},
        });
        console.log("FAQ Items Response:", response);
        this.faqItems = response.data || [];
        this.loader = false;
      } catch (error) {
        console.error("Error fetching FAQ items:", error.message);
        this.loader = false;
      }
    },
    viewCategory(id) {
      console.log("View category ID:", id);
    },
    editCategory(id) {
      console.log("Edit category ID:", id);
    },
    deleteCategory(id) {
      console.log("Delete category ID:", id);
    },
    viewFaq(id) {
      console.log("View FAQ ID:", id);
    },
    editFaq(id) {
      console.log("Edit FAQ ID:", id);
    },
    deleteFaq(id) {
      console.log("Delete FAQ ID:", id);
    },
  },


};
</script>


<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #4caf50;
}

input:checked+.slider:before {
  transform: translateX(14px);
}

.faq-intro {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.faq-intro-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.faq-intro-title1 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.faq-intro-description {
  font-size: 1rem;
  color: #555;
}

.page-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.table-style th {
  position: relative;
}

.sort-icon {
  cursor: pointer;
  margin-right: 0.5rem;
}

.action-ele {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.spinner-color {
  color: #351d4e;
}

.Intro {
  margin-top: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.modal-body {
  padding: 15px 0;
}
</style>